import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'

const Home = () => {
  const { id } = useParams()
  const [platform, optInId, linkNumber] = id.split('-')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (platform === 'cr') {
      axios
        .get(`${process.env.REACT_APP_API_URL}/affiliate/creator/redirect/${optInId}/${linkNumber}`)
        .then(res => {
          const redirectUrl = res?.data?.redirectUrl
          if (redirectUrl) {
            window.location.href = redirectUrl
          }
        })
        .catch(err => {
          console.log(err, 'err')
          setError(true)
        })
    } else {
      const fullPlatform = {
        rk: 'rakuten',
        aw: 'awin',
        ir: 'impact',
        ss: 'shareasale',
        cj: 'cj',
      }[platform]

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/affiliate/${fullPlatform}/redirect/${optInId}/${linkNumber}`
        )
        .then(res => {
          const redirectUrl = res?.data?.redirectUrl
          if (redirectUrl) {
            window.location.href = redirectUrl
          }
        })
        .catch(err => {
          console.log(err, 'err')
          setError(true)
        })
    }
  }, [platform, optInId, linkNumber])

  if (error) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'grid',
          placeItems: 'center',
        }}>
        <span style={{ fontSize: '24px' }}>
          Error redirecting the page. Please close this link and try again.
        </span>
      </div>
    )
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'grid',
        placeItems: 'center',
      }}>
      <span style={{ fontSize: '24px' }}>{id ? 'Redirecting...' : 'Incorrect Link'}</span>
    </div>
  )
}

export default Home
